import React from 'react'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import GoogleMapReact from 'google-map-react'

import IconLocation from '../../assets/svg/icon-location-24.svg'

const StyledWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const StyledMarker = styled.div`
  position: absolute;
  z-index: 1000;
  width: 10px;
  height: 10px;
`

const StyledIcon = styled(SVG)`
  position: absolute;
  bottom: 50%;
  left: 50%;
  margin-left: -25px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  svg {
    width: 100%;
    height: 100%;
    path {
      fill: ${({ theme }) => theme.colors.mayaBlue};
    }
  }

  path {
    fill: ${({ theme }) => theme.colors.mayaBlue} !important;
  }
`

const Map = ({ lat, lng, zoom, children }) => {
  const createMapOptions = () => ({
    styles: [
      {
        stylers: [
          {
            hue: '#ea8a57',
          },
          {
            saturation: 50,
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [
          {
            lightness: 50,
          },
          {
            visibility: 'simplified',
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'labels',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
    ],
  })
  return (
    <StyledWrapper>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: 'AIzaSyA6TA2fS8Yoq2RJvHCiaj4odl8kw_uhFHo',
        }}
        defaultCenter={{ lat: lat, lng: lng }}
        defaultZoom={zoom}
        options={createMapOptions}
      >
        <StyledMarker lat={lat} lng={lng}>
          <StyledIcon src={IconLocation} />
        </StyledMarker>
      </GoogleMapReact>
    </StyledWrapper>
  )
}

export default Map
