import React from 'react'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import { graphql } from 'gatsby'

import Layout from '../Layout'
import SEO from '../components/SEO'

import AsideImage from '../components/AsideImage/AsideImage'
import Button from '../components/Button/Button'
import Map from '../components/Map'

import { generalInfo } from './../constants/generalInfo'

const Social = styled.a`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  svg {
    width: 30px;
    margin-right: 5px;
  }
`

const KontaktPage = ({ data }) => (
  <Layout
    darkText
    header="Kontakt"
    asideContent={[
      <AsideImage
        noData
        darkText
        key="asideImage"
        srcImage={data.coverPhoto.childImageSharp.fluid}
      >
        <Map lat={52.10841} lng={20.7997} zoom={11}>
          {/* <AnyReactComponent lat={59.955413} lng={30.337844} text="My Marker" /> */}
        </Map>
        <Button to="https://iqdental.app/rejestracja-wizyty/6b3cd188-16dc-42ad-91ae-4a073eb8795a/new">
          Umów wizytę
        </Button>
      </AsideImage>,
    ]}
  >
    <SEO title="Kontakt | Gabinet Stomatologiczny Glossy Dental | Stomatolog Dentysta Nadarzyn" />
    <p>
      Ciszewska - Rybczyńska Katarzyna
      <br /> Gabinet Stomatologiczny
    </p>
    <p>
      ul. Jaworowa 94
      <br />
      05-830 Nadarzyn
      <br /> mazowieckie
    </p>
    <p>
      501 530 196
      <br />
      570 180 857
      <br />
      stomatologia@glossydental.pl
    </p>
    <p>
      {generalInfo.socials.map(item => (
        <Social href={item.url} target="_blank">
          <SVG src={item.icon} /> {item.nick}
        </Social>
      ))}
    </p>
  </Layout>
)

export default KontaktPage

export const pageQuery = graphql`
  query {
    coverPhoto: file(relativePath: { eq: "image2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
